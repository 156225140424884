.page-vip {
  color: $white;
  font-family: $font-family-avenir-next-lt-pro;
  @media screen and (max-width: 767px) {
    padding: 0 10px;
  }

  .article-cashback {
    .simple-article-box {
      @media screen and (max-width: 768px) {
        padding-top: 47%;
      }
    }
  }

  .article-join {
    .simple-article-box {
      @media screen and (max-width: 768px) {
        padding-top: 76%;
      }
    }

    .simple-article-lead {
      padding-right: 7rem;
      @media screen and (max-width: 768px) {
        padding-right: 0;
      }
    }
  }

  .parallux.not-full {
    .parallux-bg {
      height: 100%;
    }
  }

  .parallux.no-parallax {
    overflow: hidden;

    .parallux-bg {
      position: relative;
    }
  }

  .parallux {
    height: auto;
    position: relative;

    .parallux-bg {
      height: 100%;
      left: 0;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 0;
      transform: translateZ(0);
      will-change: transform;

      .parallux-inner {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        height: auto;
        min-height: 100%;
        min-width: 100%;
        position: relative;
        transform: translateZ(0);
        will-change: transform;
        @media screen and (max-width: 1023px) {
          background-position: top center;
          background-size: 100%;
        }
      }

      .parallax-deserve-the-best {
        background-image: url("/files/vip-club/deserve-the-best.jpg");
        @media screen and (max-width: 1023px) {
          background-image: url("/files/vip-club/1280-deserve-the-best.jpg");
        }
        @media screen and (max-width: 480px) {
          background-image: url("/files/vip-club/640-deserve-the-best.jpg");
          background-size: 100%;
        }
      }

      .parallax-programme {
        background-image: url("/files/vip-club/programme.jpg");
        @media screen and (max-width: 1023px) {
          background-image: url("/files/vip-club/1280-programme.jpg");
        }
      }

      .parallax-benefits {
        background-image: url("/files/vip-club/benefits.jpg");
        @media screen and (max-width: 1023px) {
          background-image: url("/files/vip-club/1280-benefits.jpg");
          background-size: cover;
        }
        @media screen and (max-width: 768px) {
          background-position: bottom;
          background-size: 100%;
        }
        @media screen and (max-width: 480px) {
          background-position: 0 70px;
          background-size: 100%;
        }
      }

      .parallax-cashback {
        background-image: url("/files/vip-club/cashback.jpg");
        @media screen and (max-width: 1023px) {
          background-image: url("/files/vip-club/1280-cashback.jpg");
        }
      }

      .parallax-join {
        background-image: url("/files/vip-club/join.jpg");
        @media screen and (max-width: 1023px) {
          background-image: url("/files/vip-club/1280-join.jpg");
        }
      }

      .parallax-terms {
        background-image: url("/files/vip-club/terms.jpg");
        @media screen and (max-width: 1023px) {
          background-image: url("/files/vip-club/1280-terms.jpg");
        }
      }
    }
  }

  .animated-scroll {
    .animated-scroll-title {
      opacity: 0;
      transform: translate(0, 6rem);
      transition: transform .7s ease, opacity .7s ease;
    }

    .animated-scroll-lead {
      opacity: 0;
      transform: translate(0, 6rem);
      transition: transform .7s ease .2s, opacity .7s ease .2s;

      + .animated-scroll-text {
        transition: transform .7s ease .4s, opacity .7s ease .4s;
      }
    }

    .animated-scroll-text {
      opacity: 0;
      transform: translate(0, 6rem);
      transition: transform .7s ease .2s, opacity .7s ease .2s;
    }

    &.showElement {
      .animated-scroll-title {
        opacity: 1;
        transform: translate(0, 0);
      }

      .animated-scroll-lead {
        opacity: 1;
        transform: translate(0, 0);
      }

      .animated-scroll-text {
        opacity: 1;
        transform: translate(0, 0);
      }
    }
  }

  .h2 {
    font-family: "PlayfairDisplayBlack";
    font-size: 5.5rem;
    font-weight: normal;
    line-height: 1;
    margin: 0;
    @media screen and (max-width: 1700px) {
      font-size: 4.4rem;
    }
    @media screen and (max-width: 767px) {
      font-size: 3.5rem;
    }
  }

  .h3 {
    font-family: "PlayfairDisplayBlack";
    font-size: 3.1rem;
    font-weight: normal;
    line-height: 1;
    margin: 0;
  }

  p {
    a {
      color: $gold;
      font-family: $font-family-avenir-next-lt-pro-demi;
    }

    i {
      color: $gold;
      font-family: $font-family-avenir-next-lt-pro-i;
      font-style: normal;
    }
  }

  .text-bold {
    font-family: $font-family-avenir-next-lt-pro-bold;
    font-weight: normal;
  }

  .text-gold {
    color: $gold;
  }

  .text-label {
    font-family: $font-family-avenir-next-lt-pro-bold;
    font-size: 1.2rem;
    letter-spacing: .325rem;
    text-transform: uppercase;
    @media screen and (min-width: 1700px) {
      font-size: 1.4rem;
    }
  }

  .text-lead {
    font-family: $font-family-avenir-next-lt-pro-bold;
    font-size: 1.8rem;
    letter-spacing: .1rem;
    line-height: 1.2;
    @media screen and (min-width: 1700px) {
      font-size: 2.2rem;
    }
  }

  .simple-article-wrapper {
    align-items: center;
    color: $white;
    display: flex;
    min-height: 100vh;
    padding: 5rem 0;
    position: relative;
  }

  .simple-article-box {
    flex: 1 1;
    @media screen and (max-width: 768px) {
      padding-top: 82%;
    }
  }

  .simple-article-content-left {
    max-width: 71rem;
    padding: 0 0 0 23.5rem;
    @media screen and (max-width: 1700px) {
      max-width: 44rem;
      padding: 0 0 0 6rem;
    }
    @media screen and (max-width: 1279px) {
      max-width: 43rem;
      padding: 0 0 0 3rem;
    }
    @media screen and (max-width: 768px) {
      float: none;
      margin: 0 auto;
      max-width: 50rem;
      padding: 0;
      text-align: center;
    }
    @media screen and (max-width: 767px) {
      max-width: 100%;
      padding: 0;
    }
  }

  .simple-article-content-right {
    float: right;
    max-width: 72rem;
    padding: 0 16rem 0 0;
    @media screen and (max-width: 1700px) {
      max-width: 58rem;
      padding: 0 6rem 0 0;
    }
    @media screen and (max-width: 1279px) {
      max-width: 43rem;
      padding: 0 3rem 0 0;
    }
    @media screen and (max-width: 768px) {
      float: none;
      margin: 0 auto;
      max-width: 50rem;
      padding: 0;
      text-align: center;
    }
    @media screen and (max-width: 767px) {
      max-width: 100%;
      padding: 0;
    }
  }

  .simple-article-content {
    .simple-article-title {
      margin: 0 0 4rem;
    }

    p {
      font-size: 1.6rem;
      line-height: 1.44;
      margin-bottom: 2.5rem;
      @media screen and (min-width: 1700px) {
        font-size: 1.8rem;
      }
    }

    .simple-article-lead {
      margin: 4rem 0;
      @media screen and (max-width: 767px) {
        margin: 0;
      }
    }
  }

  .deserve-article-wrapper {
    min-height: 100vh;
    position: relative;
    @media screen and (max-width: 768px) {
      min-height: 67vh;
    }
  }

  .article-deserve-the-best {
    .deserve-article-wrapper {
      @media screen and (max-width: 480px) {
        min-height: 1190px;
      }
      @media screen and (max-width: 375px) {
        min-height: 1073px;
      }
      @media screen and (max-width: 360px) {
        min-height: 1035px;
      }
      @media screen and (max-width: 320px) {
        min-height: 955px;
      }
    }
  }

  .deserve-article-particles {
    height: 100%;
    position: absolute;
    width: 100%;
  }

  .deserve-vip-logo {
    color: $white;
    font-size: 6rem;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 7rem;
    transform: translate(-50%, 0);
    transition: opacity .7s ease;

    svg {
      height: 7rem;
      width: 15.6rem;
    }
  }

  .deserve-article-wrapper.showElement .deserve-vip-logo {
    opacity: 1;
  }

  .deserve-scroll-to-next {
    animation: deserveScrollToNext 3s infinite ease;
    bottom: 6rem;
    color: $white;
    display: block;
    font-size: 3rem;
    left: 50%;
    position: absolute;
    transform: translate(-50%, 0);
    transition: opacity .7s ease;
    will-change: transform;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .deserve-article-wrapper.showElement .deserve-scroll-to-next {
    opacity: 1;
  }
  @keyframes deserveScrollToNext {
    0%,
    20% {
      opacity: 1;
      transform: translate(-50%, 0);
    }

    40% {
      opacity: 0;
      transform: translate(-50%, 2rem);
    }

    60% {
      opacity: 0;
      transform: translate(-50%, -2rem);
    }

    100%,
    80% {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }

  .deserve-article {
    bottom: 15rem;
    left: 0;
    padding: 0 0 0 23.5rem;
    position: absolute;
    width: 71rem;
    @media screen and (max-width: 1700px) {
      bottom: 10rem;
      padding: 0 0 0 17.5rem;
      width: 54rem;
    }
    @media screen and (max-width: 1366px) {
      bottom: 5rem;
      padding: 0 0 0 8rem;
      width: 44rem;
    }
    @media screen and (max-width: 1280px) {
      bottom: 10rem;
    }
    @media screen and (max-width: 1280px) and (max-height: 680px) {
      bottom: 6rem;
    }
    @media screen and (max-width: 1024px) {
      bottom: 6rem;
      padding: 0 0 0 6rem;
      width: 43rem;
    }
    @media screen and (max-width: 768px) {
      bottom: 4rem;
      left: 0;
      margin: 0 auto;
      padding: 0;
      right: 0;
      width: 50rem;
    }
    @media screen and (max-width: 480px) {
      bottom: 4rem;
      left: 0;
      margin: 0 auto;
      padding: 0;
      right: 0;
      width: 100%;
    }
  }

  .deserve-article-label {
    display: block;
    margin: 0 0 .8rem 9.4rem;
    opacity: 0;
    transform: translate(5rem, 0);
    transition: .7s ease .6s;
    @media screen and (max-width: 1700px) {
      margin: 0 0 .8rem 3rem;
    }
    @media screen and (max-width: 767px) {
      margin: 0;
      text-align: center;
    }
  }

  .deserve-article-wrapper.showElement .deserve-article-label {
    opacity: 1;
    transform: translate(0, 0);
  }

  .deserve-article-slogan {
    opacity: 0;
    transform: translate(-5rem, 0);
    transition: all .7s ease .6s;

    > span {
      display: block;
    }

    .line-2 {
      text-align: right;
    }

    .line-3 {
      text-align: center;
    }
  }

  .deserve-article-wrapper.showElement .deserve-article-slogan {
    opacity: 1;
    transform: translate(0, 0);
  }

  .benefits-article-wrapper {
    min-height: 100vh;
    overflow: hidden;
    position: relative;
    @media screen and (max-width: 768px) {
      min-height: 100%;
    }
  }

  .benefits-swiper-container {
    @extend .noselect;
    padding-top: 27px;
    z-index: auto;
    @media screen and (max-width: 768px) {
      padding-bottom: 100px;
      top: 0;
      z-index: 2;
    }
    @media screen and (max-width: 480px) {
      padding: 0;
      top: auto;
    }

    .swiper-wrapper {
      @media screen and (max-width: 480px) {
        margin: 400px 0 50px;
      }
      @media screen and (max-width: 375px) {
        margin: 360px 0 50px;
      }
      @media screen and (max-width: 320px) {
        margin: 300px 0 50px;
      }
    }

    .swiper-slide {
      h2 {
        margin-bottom: 90px;

        span {
          color: $gold;
          display: block;
          font-size: 2.3rem;
          font-style: italic;
          margin-top: 1rem;
        }
        @media screen and (max-width: 1366px) and (max-height: 800px) {
          margin-bottom: 5rem;
        }
        @media screen and (max-width: 1024px) {
          margin-bottom: 5rem;
        }
        @media screen and (max-width: 480px) {
          margin-bottom: 5px;
          text-align: center;
        }
      }

      p {
        font-size: 2rem;
        line-height: 2.75rem;
        margin-bottom: 65px;
        @media screen and (max-width: 1366px) and (max-height: 800px) {
          margin-bottom: 5rem;
        }
        @media screen and (max-width: 1024px) {
          margin-bottom: 5rem;
        }
        @media screen and (max-width: 480px) {
          margin-bottom: 0;
          text-align: center;
        }
      }

      img {
        height: 355px;
        position: absolute;
        right: 0;
        top: 0;
      }

      .swiper-slide-wrapper {
        margin: 0 auto;
        position: relative;
        width: $swiper-width;
        @media screen and (min-width: 1921px) {
          max-width: $swiper-width-xxl;
          width: $swiper-width-xl;
        }
        @media screen and (max-width: 1920px) {
          width: $swiper-width-xl;
        }
        @media screen and (max-width: 1680px) {
          width: 60vw;
        }
        @media screen and (max-width: 1366px) {
          width: $swiper-width-lg;
        }
        @media screen and (max-width: 1280px) {
          width: $swiper-width;
        }
        @media screen and (max-width: 1024px) {
          .swiper-slide-frame {
            display: none;
          }
        }
      }
    }

    .swpier-frame-wrapper {
      margin: 0 auto;
      position: relative;
      width: $swiper-width;
      @media screen and (min-width: 1921px) {
        max-width: $swiper-width-xxl;
        width: $swiper-width-xl;
      }
      @media screen and (max-width: 1920px) {
        width: $swiper-width-xl;
      }
      @media screen and (max-width: 1680px) {
        width: 60vw;
      }
      @media screen and (max-width: 1366px) {
        width: $swiper-width-lg;
      }
      @media screen and (max-width: 1280px) {
        width: $swiper-width;
      }
      @media screen and (max-width: 1024px) {
        display: none;
      }

      &.swiper-frame-disapear {
        img {
          transition: none;
          width: 0;
        }
      }

      img {
        height: 355px;
        position: absolute;
        right: 0;
        transition: width 1s cubic-bezier(.165, .84, .44, 1) 0s;
        transition-delay: .4s;
        width: 64%;
        will-change: width;
      }
    }

    .pagination-numbers {
      display: block;
      font-family: "PlayfairDisplayBlack";
      font-size: 2rem;
      margin: 0 auto;
      position: relative;
      text-align: left;
      top: -20px;
      width: $swiper-width;
      @media screen and (min-width: 1921px) {
        max-width: $swiper-width-xxl;
        width: $swiper-width-xl;
      }
      @media screen and (max-width: 1920px) {
        width: $swiper-width-xl;
      }
      @media screen and (max-width: 1680px) {
        width: 60vw;
      }
      @media screen and (max-width: 1366px) {
        width: $swiper-width-lg;
      }
      @media screen and (max-width: 1280px) {
        width: $swiper-width;
      }
      @media screen and (max-width: 768px) {
        bottom: 23px;
        position: absolute;
        text-align: center;
        top: auto;
        width: 100%;
      }
      @media screen and (max-width: 480px) {
        bottom: auto;
        top: 335px;
      }
      @media screen and (max-width: 375px) {
        bottom: auto;
        top: 300px;
      }
      @media screen and (max-width: 320px) {
        bottom: auto;
        top: 245px;
      }

      > span {
        display: inline-block;
        position: relative;

        &.pagination-current-page {
          @extend .text-gold;
          top: -22px;
          width: 12px;
        }

        &.pagination-all-page {
          left: 1px;
          top: -5px;
        }
      }

      .pagination-numbers-corss-line {
        background: $white;
        display: inline-block;
        height: 33px;
        position: relative;
        transform: rotate(45deg);
        width: 1px;
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      background-image: none;
      height: auto;
      transition: .25s cubic-bezier(.165, .84, .44, 1) 0s;
      width: auto;
      @media screen and (max-width: 768px) {
        bottom: 25px;
        top: auto;
      }
      @media screen and (max-width: 480px) {
        bottom: auto;
        top: 350px;
      }
      @media screen and (max-width: 375px) {
        bottom: auto;
        top: 315px;
      }
      @media screen and (max-width: 320px) {
        bottom: auto;
        top: 260px;
      }

      .icon {
        top: .7rem;
      }
    }

    .swiper-button-prev {
      left: 75px;
      @media screen and (max-width: 1280px) {
        left: 60px;
      }

      .icon {
        left: auto;
        right: 3.6rem;
      }
    }

    .swiper-button-next {
      right: 50px;
      @media screen and (max-width: 1280px) {
        right: 10px;
      }

      .icon {
        left: 3.6rem;
      }
    }

    .swiper-button-disabled {
      opacity: 0;
      visibility: hidden;
    }

    .anythingControls {
      position: relative;
    }

    .timer-bar {
      background: $white;
      height: 2px;
      position: absolute;
      top: -4px;
      width: 100%;
      z-index: 100;
    }
  }

  .benefit-slide-icons {
    > div {
      backface-visibility: hidden;
      opacity: 0;
      perspective: 1000;
      position: absolute;
      visibility: none;
      will-change: left, top, opacity;
      z-index: 1;

      img {
        transition: height 1s cubic-bezier(.165, .84, .44, 1) 0s;
        will-change: height;
      }

      &.benefit-slide-icons-active,
      &.benefit-slide-icons-next,
      &.benefit-slide-icons-prev {
        transition: 1s cubic-bezier(.165, .84, .44, 1) 0s;
        visibility: visible;
      }

      &.benefit-slide-icons-next,
      &.benefit-slide-icons-prev {
        opacity: .25;
        top: 36vh;
        @media screen and (max-width: 1024px) {
          height: auto;
          width: 100%;
        }
        @media screen and (max-width: 768px) {
          top: 22vh;
        }
        @media screen and (max-width: 480px) {
          top: 70px;
        }
      }

      &.benefit-slide-icons-next {
        left: calc(100% - 250px);
        transform: none;
        @media screen and (min-width: 1441px) {
          left: calc(100% - 350px);
        }
        @media screen and (max-width: 1024px) {
          left: 100%;
        }

        img {
          height: $swiper-img-height;
          @media screen and (min-width: 1441px) {
            height: $swiper-img-height-xl;
          }
          @media screen and (max-width: 480px) {
            height: auto;
            width: 100%;
          }
        }
      }

      &.benefit-slide-icons-prev {
        left: -250px;
        right: auto;
        @media screen and (min-width: 1441px) {
          left: -350px;
        }
        @media screen and (max-width: 1024px) {
          left: -100%;
        }

        img {
          height: $swiper-img-height;
          @media screen and (min-width: 1441px) {
            height: $swiper-img-height-xl;
          }
          @media screen and (max-width: 480px) {
            height: auto;
            width: 100%;
          }
        }
      }

      &.benefit-slide-icons-active {
        left: calc(50% - 250px);
        opacity: 1;
        top: 15vh;
        @media screen and (max-width: 1366px) and (max-height: 710px) {
          top: 18vh;
        }
        @media screen and (max-width: 1280px) {
          top: 18vh;
        }
        @media screen and (max-width: 768px) {
          left: calc(50% - 150px);
          top: 22vh;
        }
        @media screen and (max-width: 480px) {
          height: auto;
          left: 0;
          top: 70px;
          width: 100%;
        }

        img {
          height: 700px;
          @media screen and (max-width: 1366px) {
            height: 600px;
          }
          @media screen and (max-width: 768px) {
            height: 450px;
          }
          @media screen and (max-width: 480px) {
            height: auto;
            width: 100%;
          }
        }
      }
    }
  }

  .benefits-timebar {
    background: rgba(#888c8e, .32);
    bottom: 0;
    height: 15px;
    left: 0;
    position: absolute;
    width: 100%;
    @media screen and (max-width: 480px) {
      bottom: auto;
      top: 35rem;
    }
    @media screen and (max-width: 375px) {
      bottom: auto;
      top: 31.5rem;
    }
    @media screen and (max-width: 360px) {
      bottom: auto;
      top: 30.2rem;
    }
    @media screen and (max-width: 320px) {
      bottom: auto;
      top: 27.3rem;
    }

    .progress-timebar {
      background: #d9b400;
      height: 15px;
      left: 0;
      opacity: .4;
      position: absolute;
      top: 0;
      width: 20%;
    }
    @media screen and (max-width: 1700px) {
      height: 10px;

      .progress-timebar {
        height: 10px;
      }
    }
  }

  .terms-article-wrapper {
    min-height: 100vh;
    position: relative;
  }

  .terms-article-box {
    flex: 1 1;
  }

  .terms-article-content {
    margin: 0 auto;
    max-width: 78rem;
    padding: 8rem 0 2rem;
    text-align: center;
    @media screen and (max-width: 1700px) {
      max-width: 75rem;
    }
    @media screen and (max-width: 1024px) {
      padding: 6rem 0 2rem;
    }
    @media screen and (max-width: 768px) {
      max-width: 50rem;
    }
    @media screen and (max-width: 480px) {
      padding: 0;
    }
  }

  .terms-article-title {
    margin: .5rem 0 5rem;
    @media screen and (max-width: 480px) {
      margin: 0;
    }
  }

  .terms-article-lead {
    margin: 5rem auto 2rem;
    max-width: 58rem;
  }

  .terms-article-more-arrow {
    font-size: 3.4rem;
  }

  .terms-item-list {
    font-family: $font-family-avenir-next-lt-pro;
    font-size: 1.6rem;
    line-height: 1.44;
    list-style: none;
    margin: -.5rem 0 0;
    padding: 0;

    li {
      padding: 1.4rem 0;
      position: relative;

      + li {
        &::before {
          background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, $himalaya 50%, rgba(0, 0, 0, 0) 100%);
          content: "";
          display: block;
          height: 2px;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }
    @media screen and (min-width: 1700px) {
      font-size: 1.8rem;
    }
  }

  .page-footer {
    margin-top: 4rem;
    padding: 0 5rem 6rem;
    position: relative;
    text-align: center;
    z-index: 2;
    @media screen and (max-width: 480px) {
      padding: 0 0 6rem;
    }
  }

  .page-footer-vip-logo {
    display: block;
    float: left;
    margin-top: .4rem;

    svg {
      width: 11.4rem;
    }
  }

  .btn {
    border: 2px solid transparent;
    border-radius: 4.5rem;
    color: $white;
    cursor: pointer;
    display: inline-block;
    font-family: $font-family-avenir-next-lt-pro-bold;
    font-size: 1.2rem;
    letter-spacing: .04em;
    line-height: 1;
    min-height: auto;
    padding: 2rem 3rem;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: .25s cubic-bezier(.165, .84, .44, 1) 0s;

    &.btn-primary {
      background: transparent;
      border-color: $gold;
      color: $white;
      font-size: 1.5rem;

      &:hover {
        background-color: $gold-alpha;
      }

      &:focus {
        background-color: $gold;
      }

      &:active {
        background-color: $gold;
      }
    }
  }

  .btn-expand-footer {
    border: 2px solid $gold;
    border-radius: 50%;
    color: $white;
    display: inline-block;
    font-family: $font-family-avenir-next-lt-pro-bold;
    font-weight: normal;
    height: 6rem;
    letter-spacing: .05rem;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    transition: .25s background;
    width: 6rem;

    .icon {
      font-size: 1.8rem;
      left: 1.8rem;
      position: absolute;
      top: .5rem;
    }

    span {
      display: block;
      font-size: 1rem;
      padding-top: 2.4rem;
    }

    &:active,
    &:hover {
      background: $gold;
    }
  }

  .btn-benefits-swiper {
    @extend .btn-expand-footer;
    border: 1px solid $white;
    height: 5rem;
    width: 5rem;

    &:active,
    &:hover {
      border-color: $gold;
    }

    span {
      font-family: "PlayfairDisplayBlack";
      font-size: 2rem;
      padding-top: .4em;
    }

    .icon {
      font-size: 3.5rem;
      position: absolute;
    }
  }

  .btn-go-up {
    border: 2px solid $gold;
    border-radius: 1.7rem;
    color: $white;
    display: block;
    float: right;
    font-family: $font-family-avenir-next-lt-pro-bold;
    font-weight: normal;
    height: 5rem;
    letter-spacing: .05rem;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    transition: .25s background;
    width: 3.4rem;

    .icon {
      font-size: 1rem;
      left: 1rem;
      position: absolute;
      top: .8rem;
    }

    span {
      display: block;
      font-size: 1rem;
      padding-top: 2.4rem;
    }

    &:active,
    &:hover {
      background: $gold;
    }
  }
}
